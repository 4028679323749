<template>
    <v-dialog v-model="dialog" max-width="700px" @click:outside="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">
                    {{ editMode ? 'mdi-folder-outline' : 'mdi-folder-plus-outline' }}
                </v-icon>
                <div class="title-dialog">
                    {{ editMode ? 'Editar' : 'Agregar' }} Carpeta
                    <div class="title-subtext">
                        Completa los siguientes datos
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Nombre de la Carpeta"
                            placeholder="Ingresar Nombre"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? editFolder() : saveFolder()" tile color="primary" depressed
                               :disabled="!valid || loadingDelete" :loading="loading">
                            {{ editMode ? 'GUARDAR' : 'AGREGAR' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";

export default {
    name: "CreateFolderDialog",
    components: {KimsaSelect, KimsaTextField},
    data() {
        return {
            dialog: false,
            loading: false,
            loadingDelete: false,
            editMode: false,
            folder: null,
            name: '',
            client: null,
            clients: [],
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        valid(vm = this) {
            return !!vm.name
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            }, 500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.name = ''
            vm.client = null
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadFolder(id) {
            const vm = this
            await Axios.get(`folder/${id}`).then(res => {
                // console.log('res loadFolder', res)
                vm.folder = res.data.result.folder
                vm.editMode = true
                vm.client = vm.folder.client_id || null
                vm.name = vm.folder.name
                vm.show()
            }).catch(er => {
                console.log('error loadFolder', er)
            })
        },
        async saveFolder() {
            const vm = this
            vm.loading = true
            // console.log('saveFolder', vm)

            let payload = {name: vm.name, client_id: vm.client}

            // console.log('payload', payload)

            await Axios.post('folder/new', payload).then(res => {
                // console.log('res saveFolder',res)
                vm.toast('Carpeta creada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error saveFolder', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async editFolder() {
            const vm = this
            vm.loading = true

            // console.log('editFolder', vm)

            let payload = {name: vm.name, client_id: vm.client}

            // console.log('payload', payload)

            await Axios.patch(`folder/${vm.folder.id}/edit`, payload).then(res => {
                // console.log('res editFolder',res)
                vm.toast('Carpeta editada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error editFolder', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.title-dialog {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.title-subtext {
    font-size: 11px;
    color: #a8a8a8;
    line-height: .5;
    text-transform: none;
    font-weight: normal;
}

.label {
    font-size: 15px;
    color: #333333;
}

</style>